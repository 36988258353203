<template>
	<transition-group name="fade">
		<component
			:is="currentPopup"
			@close="closePopup"
			:class="[desktopClass, positionClass]"
			@mounted="handleComponentMounted"
			ref="dialogContainer"
			:style="pos"
			:post-id="postId"
			:can-be-deleted="canBeDeleted"
		/>
	</transition-group>
</template>

<script setup lang="ts">
import { ref, defineAsyncComponent, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useCommonStore } from '@/stores/common'
import eventHelper from '@/helpers/event.helper.ts'

const commonStore = useCommonStore()
const route = useRoute()
const currentPopup = ref(null)
const desktopClass = ref(null)
const positionClass = ref(null)
const postId = ref(null)
const containerHeight = ref(0)
const dialogContainer = ref(null)
const pos = ref(null)
const canBeDeleted = ref(null)

const popupMap = new Map([
	['profileMenu', defineAsyncComponent(() => import('./ProfileMenu.vue'))],
	['postOptions', defineAsyncComponent(() => import('./PostOptions.vue'))]
])

eventHelper.on('setPopupData', (data) => {
	const right = window.innerWidth - data.position.left - data.position.width + 10
	const top = data.position.top + data.position.height + 12
	postId.value = data.postId ? data.postId : null
	pos.value = {
		right: `${right}px`
	}
	canBeDeleted.value = data.canBeDeleted ? data.canBeDeleted : null

	if (window.innerHeight - (top + containerHeight.value + data.position.height + 50) > 0) {
		pos.value.top = `${top}px`
	} else {
		pos.value.bottom = `${window.innerHeight - data.position.top}px`
		positionClass.value = 'bottom'
	}

	desktopClass.value = !commonStore.isMobileDevice && data.dropdownMode ? 'desktop-dropdown' : null
	currentPopup.value = popupMap.get(data.popup)
})
eventHelper.on('clearPopupData', () => {
	currentPopup.value = null
	desktopClass.value = null
	positionClass.value = null
	postId.value = null
	containerHeight.value = 0
})

const closePopup = () => {
	eventHelper.emit('clearPopupData')
}

const getComponentHeight = () => {
	if (dialogContainer.value) {
		const { height } = dialogContainer.value.$el.getBoundingClientRect()
		return height
	}
	return null
}

const handleComponentMounted = () => {
	containerHeight.value = getComponentHeight()
}

watch(route, () => {
	closePopup()
})
</script>

<style style lang="scss">
.desktop-dropdown {
	position: fixed;
	z-index: 1001;

	.dialog-backdrop {
		background: transparent;
		position: static;
		border-radius: 16px 6px 16px 16px;

		&:before {
			content: '';
			position: fixed;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
	}

	&.bottom {
		.dialog-backdrop {
			border-radius: 16px 16px 6px 16px;
		}
		.dialog {
			border-radius: 16px 16px 6px 16px;
		}
	}

	.dialog {
		position: relative;
		width: auto;
		border-radius: 16px 6px 16px 16px;
		background: #2c2d37;
		box-shadow:
			5px 4px 6.2px 0px rgba(0, 0, 0, 0.1),
			5px 20px 24.8px 11px rgba(0, 0, 0, 0.2);
	}
	.dialog-header,
	.dialog-close-btn {
		display: none;
	}

	.profile-menu {
		margin: -9px -10px;
	}

	.profile-menu-item {
		padding: 3px 10px;
		font-size: 14px;
		height: auto;
		transition:
			background-color 0.2s ease-in-out,
			color 0.2s ease-in-out;
		border-radius: 9px;
		min-height: 36px;
		display: flex;
		align-items: center;
		color: #bebec9;

		&:hover {
			color: #ffffff;
			background-color: #25272c;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	.profile-menu {
		margin-bottom: 20px;
	}
}
</style>
